import { Fragment } from "react";
import { Heading } from "@chakra-ui/react";

export function Blog() {
  return (
    <Fragment>
      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "0vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "5vh" }}
      >
        oferta
        <span style={{ color: "#66C7F4" }}></span>
      </Heading>

      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "20vh" }}
        marginBottom={{ base: "0vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "10vh" }}
      >
       <span style={{ color: "#f10965" }}>redacción</span> 
      </Heading>

      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "20vh" }}
        marginBottom={{ base: "0vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "10vh" }}
      >
        THEBRIDGE100 <span style={{ color: "#f10965"} }>THEBRIDGE100</span>
      
      </Heading>

      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "80vh" }}
        marginBottom={{ base: "0vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "50vh" }}
      >
        <span style={{ color: "#f10965" }}>NO</span>
      </Heading>
    </Fragment>
  );
}
