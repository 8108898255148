import {
  Heading,
  Card,
  Text,
  CardBody,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { Fragment } from "react";

export function IndividualFormat() {
  return (
    <Fragment>
      <SimpleGrid columns={{ base: 1, md: 4 }} gap="15px">
        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
              <Heading size="md" className="benefits-heading">
                Próxima edición
              </Heading>
              <Text><b>Inicio:</b> Lunes 05 de Mayo de 2025.</Text>
            </Stack>
          </CardBody>
        </Card>

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
              <Heading size="md" className="benefits-heading">
                Duración
              </Heading>
              <Text>
                <b>Horario:</b> Todos los lunes del mes de 16:00 a 18:30.
              </Text>
            </Stack>
          </CardBody>
        </Card>
      

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
              <Heading size="md" className="benefits-heading">
                Plazas limitadas
              </Heading>
              <Text>
              <b>Cupo:</b> Mínimo 10 y máximo 15 asistentes por edición.
              </Text>
            </Stack>
          </CardBody>
        </Card>

       

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
              <Heading size="md" className="benefits-heading">
             200 €
              </Heading>

              <Text>
                Primeras 5 plazas reservadas a 150€.
              </Text>
            </Stack>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Fragment>
  );
}
