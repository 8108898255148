import React from "react";
import { Fragment } from "react";
import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { Helmet } from "react-helmet";
import { ChangeDocumentTitle } from "../Utils/Utilities";
import {
  Heading,
  Text,
  SimpleGrid,
  Flex,
  Image,
  Stack,
  Card,
  CardBody,
} from "@chakra-ui/react";

export function VideoPodcast() {
  ChangeDocumentTitle("Leonardo Poza - Video Podcast");

  return (
    <Fragment>
      <Helmet>
        <meta
          property="og:title"
          content="Leonardo Poza - Video Podcast"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Explora entrevistas en vídeo con expertos en contratación tech, creación de equipos y atracción de talento. Consejos reales, experiencias y mejores prácticas en un solo lugar."
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content="https://leonardopoza.com/header_image_sharing.jpg"
          data-react-helmet="true"
        />
        <meta
          property="og:url"
          content="https://leonardopoza.com/recruiting-academy/"
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />

        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />
        <meta
          name="twitter:title"
          content="Leonardo Poza - Video Podcast"
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content="Explora entrevistas en vídeo con expertos en contratación tech, creación de equipos y atracción de talento. Consejos reales, experiencias y mejores prácticas en un solo lugar."
          data-react-helmet="true"
        />
        <meta
          name="twitter:image"
          content="https://leonardopoza.com/header_image_sharing.jpg"
          data-react-helmet="true"
        />
      </Helmet>

      <WithSubnavigation />

      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "0vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "10vh" }}
      >
        El backstage del mundo tech<span style={{ color: "#04F06A" }}>.</span>
      </Heading>

      <Heading
        className="hero-skills"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "10vh", md: "10vh" }}
      >
        #entrevista. &nbsp;&nbsp; #formación.&nbsp;&nbsp;
        #atracción.&nbsp;&nbsp; #contratación.
      </Heading>

      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "220px", md: "220px" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4} marginTop={{ base: "40px", md: "40px" }}>
          <Text>
            Aquí encontrarás entrevistas sin filtros sobre contratación, cultura
            de ingeniería, crecimiento de equipos y los entresijos del mundo
            tech. Conversaciones reales con gente que ha vivido lo bueno, lo
            malo y lo inesperado de construir equipos y hacer que funcionen.
          </Text>
          <Text>
            Todas las entrevistas están promovidas por{" "}
            <a href="https://getmanfred.com" target={"_blank"} rel="noreferrer">
              Manfred
            </a>
            , las llevo a cabo yo mismo y se graban en Stage One, un espacio
            cedido por{" "}
            <a href="https://sngular.com" target={"_blank"} rel="noreferrer">
              Sngular
            </a>{" "}
            para que estas charlas puedan tener el escenario que merecen.
          </Text>
          <Text>
            Si quieres saber cómo se contrata, escala y lidera en tech sin humo
            ni postureo, este es tu sitio. Y si conoces a alguien que tenga una
            historia potente que contar y pueda aportar valor a la comunidad,
            ¡cuéntanoslo! Nos encantará descubrir nuevas voces y sumar más
            perspectivas a este backstage.
          </Text>{" "}
        </Stack>
        <Flex>
          <Image
            boxShadow={"xl"}
            rounded={"md"}
            alt={"feature image"}
            height={"400"}
            width="100%"
            src={"/video-podcast-header.png"}
            objectFit={"cover"}
            marginTop={"-3"}
          />
        </Flex>
      </SimpleGrid>

      <Heading
        className="where-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "10vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Episodios
        <span style={{ color: "#04F06A" }}>:</span>
      </Heading>

      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "220px", md: "220px" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <a
            href="https://www.youtube.com/watch?v=srZypNl8peI"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="video-podcast-images/creando-equipos-jose-aplanet.png"
                  alt="Cómo montar equipos para startups"
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="sm" color={"#f10965"}>
                    Episodio 3
                  </Heading>
                  <Heading size="md">Cómo montar equipos para startups</Heading>
                  <Text>
                    José Álvarez, CTO de Aplanet, comparte su experiencia ayudando a startups a formar
                    equipos equilibrados. Hablamos con él sobre los errores de
                    las statups, la importancia de la cultura empresarial, la
                    contratación, la comunicación interna y la evolución de los
                    modelos de trabajo.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://www.youtube.com/watch?v=BaS7J5mX2oc"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="video-podcast-images/engineering-managers.png"
                  alt="Engineering Managers Talk"
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="sm" color={"#f10965"}>
                    Episodio 2
                  </Heading>
                  <Heading size="md">
                    Charlando con Engineering Managers
                  </Heading>
                  <Text>
                    Fue un autentico placer poder moderar una charla sobre el
                    rol del Engineering Manager entre 4 personas que llevan
                    ejerciendo mucho tiempo dicho rol del que tanto se habla. Un
                    buen rato con Bea (Factorial), Jaime Muñoz (Marketgoo),
                    Javier de Arcos (Celonis) y Felix López.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://www.youtube.com/watch?v=7rAu-747VV8"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="video-podcast-images/escalando-equipos.png"
                  alt="Escalando equipos."
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="sm" color={"#f10965"}>
                    Episodio 1
                  </Heading>
                  <Heading size="md">
                    Escalando un equipo técnico con AIDA
                  </Heading>
                  <Text>
                    En AIDA han diseñado un proceso de contratación que les
                    permite entrevistar el talento que necesitan, tomar la mejor
                    decisión e incorporar a los nuevos fichajes a la compañía de
                    manera ágil y funcional... Pero, ¿cómo lo han hecho?
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
      </SimpleGrid>

      <Footer />
    </Fragment>
  );
}
